import React from 'react';

import './index.scss';

import { Section, Container, Row, Column } from 'components/atoms';
import SelectedClientsItem from 'components/molecules/SelectedClientsItem';

const SelectedClients = ({ data, className }) => {
  const { clients } = data;

  return (
    <Section className={className}>
      <Container type="fluid">
        <Row>
          <Column
            lg="12"
            col="10"
            md="8"
            className={`${className}__container offset-lg-0 offset-1 offset-md-2`}
            onMouseDown={e => e.preventDefault()}
            onContextMenu={e => e.preventDefault()}
          >
            {clients.map((client, id) => {
              const {
                description,
                image: { url },
              } = client;

              return (
                <SelectedClientsItem
                  className={`${className}__item`}
                  info={description}
                  image={url}
                  key={id}
                />
              );
            })}
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default SelectedClients;
